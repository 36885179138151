import React from "react";
import { Button, MenuItem, MenuList, Popover, Typography } from "@cuda-networks/bds-core";
import { useSession } from "next-auth/react";
import { useCsrfTokenContext } from "./CsrfTokenContext";
import AccountIcon from "@cuda-networks/bds-core/dist/Icons/Core/Account";
import DropDownArrowIcon from "@cuda-networks/bds-core/dist/Icons/Controls/DropDownArrow";
import { useMixpanel } from "./TrialsHubMixpanelProvider";
import { log } from "@pam/common/base-logger";
import { LocalizedString } from "@barracuda-internal/messages-lib";
import {
  COMMON_AUTH_BUTTON_SIGN_IN,
  COMMON_AUTH_BUTTON_SIGN_OUT,
  // eslint-disable-next-line import/extensions
} from "../lids/common.mjs";

const AuthButton = ({ signOutUrl }: { signOutUrl: string }) => {
  const csrfToken = useCsrfTokenContext();

  const { data: session, status } = useSession();
  const loading = status === "loading";

  const [authButtonEl, setAuthButtonEl] = React.useState<null | HTMLButtonElement>();
  const handleClose = (): void => {
    // eslint-disable-next-line unicorn/no-null
    setAuthButtonEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAuthButtonEl(event.currentTarget);
  };

  const mixpanel = useMixpanel();

  return (
    <Typography variant="body1" component="div">
      {loading ? (
        <div className="container">...</div>
      ) : session?.user ? (
        <div className="container" style={{ padding: "0 10px", margin: "10px" }}>
          <Button
            size="medium"
            variant="text"
            onClick={handleClick}
            style={{
              fontWeight: "normal",
              color: "white",
              // in lieu of edge="end" support for Buttons
              marginRight: "-15px",
              padding: "4px 12px",
            }}
            startIcon={<AccountIcon style={{ fontSize: "36px" }} />}
            endIcon={<DropDownArrowIcon />}
          >
            {session.user.email}
          </Button>
          <Popover
            id="auth-button-menu"
            anchorEl={authButtonEl}
            keepMounted
            open={Boolean(authButtonEl)}
            onClose={handleClose}
            disableScrollLock
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <form
              method="post"
              action={signOutUrl}
              onSubmit={() => {
                // catch error if mixpanel is not set up (it is not initialized on every page)
                try {
                  mixpanel.reset();
                } catch (error) {
                  log.warn({ error }, "Ignoring mixpanel reset failure (it is probably not set up on this page)");
                }
              }}
            >
              <input type="hidden" name="csrfToken" value={csrfToken}></input>
              <MenuList autoFocusItem id="auth-button-menu-list">
                <MenuItem component="button" type="submit">
                  <LocalizedString lid={COMMON_AUTH_BUTTON_SIGN_OUT} />
                </MenuItem>
              </MenuList>
            </form>
          </Popover>
        </div>
      ) : (
        <div className="container" style={{ padding: "0 10px", margin: "10px" }}>
          <form method="post" action="/api/auth/signin/bcc-login-app">
            <input type="hidden" name="csrfToken" value={csrfToken}></input>
            <Button
              type="submit"
              size="medium"
              variant="text"
              onClick={handleClick}
              style={{
                fontWeight: "normal",
                color: "white",
                // in lieu of edge="end" support for Buttons
                marginRight: "-15px",
                padding: "4px 12px",
              }}
            >
              <LocalizedString lid={COMMON_AUTH_BUTTON_SIGN_IN} />
            </Button>
          </form>
        </div>
      )}
    </Typography>
  );
};

export { AuthButton };
