import React from "react";
import { setLocale, getAllLocales } from "@barracuda-internal/messages-lib";
import { Button, Menu, MenuItem } from "@cuda-networks/bds-core";
import { isProduction } from "@pam/common/environment";
import DropDownArrowIcon from "@cuda-networks/bds-core/dist/Icons/Controls/DropDownArrow";
import { useCookies } from "react-cookie";

export function LanguageChooser() {
  const locales = getAllLocales();

  if (!isProduction()) locales.en_QA = "English (QA)";

  const [cookies, setCookie] = useCookies(["CLOUD_LOCALE"]);
  const currentLocale = (cookies.CLOUD_LOCALE as string) || "en_US";

  React.useEffect(() => {
    void setLocale(currentLocale);
  }, [currentLocale]);

  const [languageButtonEl, setLanguageButtonEl] = React.useState<null | HTMLButtonElement>();

  const handleClose = (): void => {
    // eslint-disable-next-line unicorn/no-null
    setLanguageButtonEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setLanguageButtonEl(event.currentTarget);
  };

  const handleChange = (code: string) => {
    setCookie("CLOUD_LOCALE", code, {
      path: "/",
      maxAge: 60 * 60 * 24 * 365, // 1 year
      secure: true,
    });
    void setLocale(code);
    handleClose();
  };

  return (
    <>
      <Button
        id="language-button"
        size="medium"
        variant="text"
        onClick={handleClick}
        style={{
          fontWeight: "normal",
          color: "white",
          // in lieu of edge="end" support for Buttons
          marginRight: "-15px",
          padding: "4px 12px",
        }}
        endIcon={<DropDownArrowIcon />}
      >
        {locales[currentLocale]}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={languageButtonEl}
        keepMounted
        open={Boolean(languageButtonEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {Object.entries(locales).map(([code, name]: [string, string]) => (
          <MenuItem key={code} selected={code === currentLocale} onClick={() => handleChange(code)}>
            {name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
